import React from "react";
import PropTypes from "prop-types";
// Styles
import "./Menu.css";
// Icons
import { Fade, Slide } from "react-awesome-reveal";

/*
 * TODO: Refactor Menu as a functional component
 *
 * Requirements:
 * - Create a custom hook to implement dark mode named useDarkMode
 * - Switch from setState to the useDarkMode hook
 * - Use function closures instead of this for callbacks and event handlers
 * - Menu logic and behavior should remain the same
 *
 */
class Menu extends React.Component {
  state = {
    darkMode: true,
  };

  handleOnChangeMode = () => {
    this.setState((prevState) => ({
      ...prevState,
      darkMode: !prevState.darkMode,
    }));
  };

  render() {
    const brandLogoSM = `${process.env.PUBLIC_URL}/logo.svg`;
    const brandLogo = `${process.env.PUBLIC_URL}/logo.svg`;

    // const brandLogo = this.state.darkMode
    //   ? `${process.env.PUBLIC_URL}/logo_white.svg`
    //   : `${process.env.PUBLIC_URL}/logo.svg`;

    return (
      <div>
        <div className="top-bar-sm">
          <a href="/" className="logo">
            <Slide>
              <img src={brandLogoSM} alt="CFMoto Configurator" />
            </Slide>

            <div className="logo-title">
              CFORCE 450L
              {/* <span>
                <Fade delay={1e3} cascade damping={1e-1}>
                  450L
                </Fade>
              </span> */}
            </div>
          </a>
        </div>
        <div className="menu-container">
          <a href="/" className="logo" title="CFMoto Configurator">
            <img src={brandLogo} alt="CFMoto Configurator" />
          </a>
          <Fade cascade damping={0.1}>
            <ul className="menu-nav">
              {this.props.items && this.props.items.length === 0 ? (
                <h1 className="gallery-title">Your CFMoto Configurator</h1>
              ) : null}
              {this.props.items.map((item, i) => (
                <li
                  key={item}
                  onClick={() => this.props.onSelectItem(i)}
                  className={this.props.selectedItem === i ? "selected" : null}
                >
                  <h2>{item}</h2>
                </li>
              ))}
            </ul>
          </Fade>
          <div id="menu-right-side" className="logo-title">
            <span>
              <Fade delay={1e3} cascade damping={1e-1}>
                CFORCE 450L
              </Fade>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

Menu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  selectedItem: PropTypes.number,
  onSelectItem: PropTypes.func,
};

export default Menu;
