import React from "react";

import PropTypes from "prop-types";
import { formatPrice } from "../../utils";
// Styles
import "./Settings.css";
// Components
import Option from "../Option";
import { Fade } from "react-awesome-reveal";
import Modal from "react-modal";
Modal.setAppElement("#root");
/*
 * TODO: Refactor Editor to leverage React hooks
 *
 * Requirements:
 * - store selectedOptions in React state using the useState hook
 * - initialize state using lazy initialization
 * - use other React hooks if needed
 *
 */
const Settings = ({
  config = null,
  settings = null,
  onSelectOption = () => null,
  onSelectOptionModel = () => null,
  onChangeOption = () => null,
  selectedSubItem = 0,
  models = null,
}) => {
  const apiRef = config?.sketchFab?.apiRef;
  //

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [modalIsOpen3, setIsOpen3] = React.useState(false);
  const [modalIsOpen4, setIsOpen4] = React.useState(false);
  const selectedModel = models?.find((model) => model?.key === config?.model);
  // const selectedStyling = selectedModel?.styling?.find(
  //   (styling) => styling.value === config?.styling
  // );
  const selectedStorage = selectedModel?.storage?.find(
    (storage) => storage.value === config?.storage
  );
  const selectedTowings = selectedModel?.towings?.find(
    (towings) => towings.value === config?.towings
  );

  const hidePart = (value) => {
    // Paint
    if (
      value === 11 ||
      value === 12 ||
      value === 13 ||
      value === 14 ||
      value === 15 ||
      value === 16 ||
      value === 17 ||
      value === 18
    ) {
      apiRef.current.getMaterialList((err, materials) => {
        const plasticMaterial = materials.find(
          (material) => material.name === "Body_Color"
        );

        plasticMaterial.channels.AlbedoPBR.factor = 1;
        plasticMaterial.channels.AlbedoPBR.enable = true;
        plasticMaterial.channels.DiffuseColor.enable = false;

        if (value === 11) {
          plasticMaterial.channels.AlbedoPBR.color = [
            1, 0.04970656598412723, 0.004776953480693729,
          ];
        } else if (value === 12) {
          plasticMaterial.channels.AlbedoPBR.color = [
            0.19806931955994886, 0.13563332965520566, 0.06301001765316767,
          ];
        } else if (value === 13) {
          plasticMaterial.channels.AlbedoPBR.color = [
            0.021219010376003558, 0.03433980680868217, 0.021219010376003558,
          ];
        } else if (value === 14) {
          plasticMaterial.channels.AlbedoPBR.color = [
            0.533276404010505, 0.012286488356915872, 0.0033465357638991595,
          ];
        } else if (value === 15) {
          plasticMaterial.channels.AlbedoPBR.color = [
            0.06124605423161761, 0.06124605423161761, 0.06124605423161761,
          ];
        } else if (value === 16) {
          plasticMaterial.channels.AlbedoPBR.color = [0, 0, 0];
        } else if (value === 17) {
          plasticMaterial.channels.AlbedoPBR.color = [
            0.08021982031446831, 0.14126329114027164, 0.06662593864377286,
          ];
        } else if (value === 18) {
          plasticMaterial.channels.AlbedoPBR.color = [
            0, 0.08021982031446831, 0.18116424424986022,
          ];
        } else {
          plasticMaterial.channels.AlbedoPBR.color = [
            1, 0.04970656598412723, 0.004776953480693729,
          ];
        }

        apiRef.current.setMaterial(plasticMaterial, () => {
          //console.log("Updated chair color");
        });
      });
    }
    // Styling
    else if (value === 1090) {
      apiRef.current.hide(1017);
      apiRef.current.show(value);
    } else if (value === 1017) {
      apiRef.current.hide(1090);
      apiRef.current.show(value);
    }
    // Wheels
    else if (value === 1063) {
      apiRef.current.hide(1049);
      apiRef.current.show(value);
    } else if (value === 1049) {
      apiRef.current.hide(1063);
      apiRef.current.show(value);
    }
    // Storage
    else if (value === 11170000) {
      apiRef.current.hide(1117);
    } else if (value === 1117) {
      apiRef.current.show(1117);
    }
  };
  const selectedOptions = settings?.reduce(
    (acc, setting) => ({
      ...acc,
      [setting.prop]:
        setting.options.find(
          (option) => option.value === config[setting.prop]
        ) ?? [],
    }),
    {}
  );

  function closeModal() {
    setIsOpen(false);
    setIsOpen2(false);
  }
  function closeModal3() {
    onSelectOption("storage", "nstorage");
    hidePart(1383000);
    setIsOpen(false);
    setIsOpen2(false);
    setIsOpen3(false);
    setIsOpen4(false);
  }
  function closeModal4() {
    apiRef.current.hide(1467);
    apiRef.current.hide(1455);
    setIsOpen(false);
    setIsOpen2(false);
    setIsOpen3(false);
    setIsOpen4(false);
    onSelectOption("towings", "towing_1");
  }
  function removeSportsBarModal() {
    apiRef.current.hide(1491);
    apiRef.current.hide(743);
    apiRef.current.show(1289);
    apiRef.current.show(1515);
    setIsOpen(false);
    setIsOpen2(false);
    onSelectOption("styling", "nstyle");
  }
  function removeBedTentModal() {
    apiRef.current.hide(1289);
    apiRef.current.hide(1515);
    apiRef.current.show(743);
    apiRef.current.show(1491);
    setIsOpen(false);
    setIsOpen2(false);
    onSelectOption("storage", "nstorage");
  }
  // removeAffiliatedModal
  function removeTowingModal() {
    apiRef.current.hide(1467);
    apiRef.current.hide(1455);
    // apiRef.current.show(1491);
    setIsOpen3(false);
    onSelectOption("towings", "towing_1");
  }
  function removeAffiliatedModal() {
    apiRef.current.hide(1383);
    apiRef.current.hide(1245);
    apiRef.current.hide(1393);
    apiRef.current.hide(1359);
    apiRef.current.hide(1371);
    setIsOpen4(false);
    onSelectOption("storage", "nstorage");
  }

  const customStyles = {
    content: {
      top: "118px",
      left: "auto",
      right: "0",
      borderRadius: "0",
      border: "none",
      bottom: "auto",
      padding: "20px 40px",
      width: "280px",
      height: "100vh",
      background: "#222222",
      transform: "translateX(0%)",
      transition: "transform 300ms ease-out 150ms",
    },
  };
  config.sketchFab = { apiRef };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Conflict Sport Bar with Bed Tent Modal"
      >
        <Fade>
          <h2 className="requirement-title">REQUIREMENT</h2>
          <div className="requirement-text">
            <b>Sports Bar</b> must be removed to show <b>Bed Tent</b> .
          </div>
          <form className="requirement-form">
            <button className="requirement-btn" onClick={closeModal}>
              Cancel
            </button>
            <button
              className="requirement-btn requirement-btn-2"
              onClick={removeSportsBarModal}
            >
              Remove
            </button>
          </form>
        </Fade>
      </Modal>
      <Modal
        isOpen={modalIsOpen2}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Conflict Bed Tent with Sport Bar Modal"
      >
        <Fade>
          <h2 className="requirement-title">REQUIREMENT</h2>
          <div className="requirement-text">
            <b>Bed Tent</b> must be removed to show <b>Sports Bar</b>.
          </div>
          <form className="requirement-form">
            <button className="requirement-btn" onClick={closeModal}>
              Cancel
            </button>
            <button
              className="requirement-btn requirement-btn-2"
              onClick={removeBedTentModal}
            >
              Remove
            </button>
          </form>
        </Fade>
      </Modal>
      <Modal
        isOpen={modalIsOpen3}
        onRequestClose={closeModal3}
        style={customStyles}
        contentLabel="Conflict Towing with Affiliated Modal"
      >
        <Fade>
          <h2 className="requirement-title">REQUIREMENT</h2>
          <div className="requirement-text">
            <b>{selectedTowings?.label}</b> must be removed to show{" "}
            <b>{selectedStorage?.label}</b>.
          </div>
          <form className="requirement-form">
            <button className="requirement-btn" onClick={closeModal3}>
              Cancel
            </button>
            <button
              className="requirement-btn requirement-btn-2"
              onClick={removeTowingModal}
            >
              Remove
            </button>
          </form>
        </Fade>
      </Modal>
      <Modal
        isOpen={modalIsOpen4}
        onRequestClose={closeModal4}
        style={customStyles}
        contentLabel="Conflict Towing with Affiliated Modal"
      >
        <Fade>
          <h2 className="requirement-title">REQUIREMENT</h2>
          <div className="requirement-text">
            <b>{selectedStorage?.label}</b> must be removed to show{" "}
            <b>{selectedTowings?.label}</b>.
          </div>
          <form className="requirement-form">
            <button className="requirement-btn" onClick={closeModal4}>
              Cancel
            </button>
            <button
              className="requirement-btn requirement-btn-2"
              onClick={removeAffiliatedModal}
            >
              Remove
            </button>
          </form>
        </Fade>
      </Modal>
      <div className="settings">
        {settings?.map((setting, i) => {
          if (
            !setting.options ||
            setting.options.length === 0 ||
            (selectedSubItem !== i && selectedSubItem !== setting.ref)
          ) {
            return null;
          }
          return (
            <Fade key={i}>
              <div className="settings-group">
                <div
                  id="list-item-ink"
                  className={`settings-options settings-options-${setting.type}`}
                >
                  {setting.options.map((option, i) => (
                    // <Fade key={i} cascade damping={0.1}>
                    <Option
                      {...option}
                      key={option.selector}
                      type={setting.type}
                      price={formatPrice(option.price)}
                      active={config?.[setting.prop] === option.value}
                      onSelectOption={(value) =>
                        onSelectOption(setting.prop, value)
                      }
                      onSelectOptionModel={(value) => hidePart(value)}
                      required={option.required}
                    />
                    // </Fade>
                  ))}
                </div>

                {setting.type !== "text" ? (
                  <div className="settings-group-label">
                    <span>{selectedOptions?.[setting.prop]?.label}</span>
                    <span className="price">
                      {formatPrice(selectedOptions?.[setting.prop]?.price)}
                    </span>
                  </div>
                ) : null}
                {selectedOptions?.[setting.prop]?.benefits ? (
                  <div className="settings-group-benefits">
                    <p>
                      Model {config.model.toUpperCase()}{" "}
                      {selectedOptions[setting.prop].label} includes:
                    </p>
                    <ul>
                      {selectedOptions?.[setting.prop]?.benefits?.map(
                        (benefit, i) => (
                          <li key={i}>{benefit}</li>
                        )
                      )}
                    </ul>
                  </div>
                ) : null}
                {setting.disclaimer_2 ? (
                  <p className="settings-group-disclaimer">
                    {setting.disclaimer_2}
                  </p>
                ) : null}
              </div>
            </Fade>
          );
        })}
      </div>
    </>
  );
};

Settings.propTypes = {
  config: PropTypes.object,
  settings: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.string,
      prop: PropTypes.string,
      options: PropTypes.array,
    })
  ),
  onSelectOption: PropTypes.func,
  onChangeOption: PropTypes.func,
};

export default Settings;
