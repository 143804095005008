import React from "react";
import { models, initialConfig } from "../../../data";
// Styles
import "./Home.css";
// Components
import Menu from "../../Menu";
import MenuSub from "../../MenuSub";
import Footer from "../../Footer";
import Settings from "../../Settings";
import Summary from "../../Summary";
import Viewer from "../../Viewer";
import Preloader from "../../Preloader";

/*
 * TODO: Refactor App as a functional component
 *
 * Requirements:
 * - Compute total price using React hooks only when config or selectedModel change
 * - Create a custom hook to use localStorage to store the current step and config
 * - Switch from setState to the useLocalStorage hook
 * - Use function closures instead of this for callbacks and event handlers
 * - App logic and behavior should remain the same
 *
 */

class Home extends React.Component {
  state = {
    currentNavStep: 0,
    currentStep: 0,
    currentSubStep: 0,
    config: initialConfig?.["s"] ?? null,
    annotationId: 0,
    isStudio: 1,
    isModelLoaded: false,
  };

  get selectedModel() {
    document.body.classList.remove("gallery-layout");
    return models.find((model) => model?.key === this.state.config?.model);
  }
  get navSteps() {
    return [
      {
        name: "Paint",
      },
      {
        name: "Front Bumper",
      },
      {
        name: "Handguard",
      },
      {
        name: "Cargo Box",
      },
      {
        name: "Summary",
      },
    ];
  }
  get steps() {
    return [
      {
        name: "exterior",
        settings: [
          {
            label: "Select color",
            parent: "root",
            type: "text",
            prop: "color",
            name: "Paint",
            options: this.selectedModel?.colors ?? [],
          },
          {
            name: "Front Bumper",
            label: "Select styling",
            parent: "root",
            type: "text",
            multiple: true,
            prop: "styling",
            options: this.selectedModel?.styling ?? [],
          },

          {
            name: "Handguard",
            parent: "root",
            label: "Select wheels",
            type: "text",
            prop: "wheels",
            options: this.selectedModel?.wheels ?? [],
          },
          {
            name: "Cargo Box",
            parent: "root",
            label: "Select storage",
            type: "text",
            prop: "storage",
            options: this.selectedModel?.storage ?? [],
          },
        ],
      },
      {
        name: "summary",
        settings: [],
      },
    ];
  }

  get totalPrice() {
    const basePrice =
      this.selectedModel?.types?.find(
        (type) => type.value === this.state.config?.car_type
      )?.price ?? 0;
    const colorPrice =
      this.selectedModel?.colors?.find(
        (color) => color.value === this.state.config?.color
      )?.price ?? 0;
    const stylingPrice =
      this.selectedModel?.styling?.find(
        (styling) => styling.value === this.state.config?.styling
      )?.price ?? 0;
    const wheelsPrice =
      this.selectedModel?.wheels?.find(
        (wheels) => wheels.value === this.state.config?.wheels
      )?.price ?? 0;
    const storagePrice =
      this.selectedModel?.storage?.find(
        (storage) => storage.value === this.state.config?.storage
      )?.price ?? 0;
    const runningBordsPrice =
      this.selectedModel?.runningBords?.find(
        (runningBords) => runningBords.value === this.state.config?.runningBords
      )?.price ?? 0;
    const interiorColorPrice =
      this.selectedModel?.interiorColors?.find(
        (interiorColor) =>
          interiorColor.value === this.state.config?.interior_color
      )?.price ?? 0;
    const towingsPrice =
      this.selectedModel?.towings?.find(
        (towing) => towing.value === this.state.config?.towings
      )?.price ?? 0;
    const interiorLayoutPrice =
      this.selectedModel?.interiorLayouts?.find(
        (interiorLayout) =>
          interiorLayout.value === this.state.config?.interior_layout
      )?.price ?? 0;

    return (
      basePrice +
      colorPrice +
      stylingPrice +
      wheelsPrice +
      storagePrice +
      runningBordsPrice +
      interiorColorPrice +
      towingsPrice +
      interiorLayoutPrice
    );
  }

  goToStep = (step) => {
    if (step === 1) {
      this.setState({ currentStep: step });
      this.setState({ currentSubStep: 4 });
      this.goToAnnotationId(4);
    } else {
      this.setState({ currentStep: step });
      this.setState({ currentSubStep: 0 });
      this.goToAnnotationId(0);
    }
  };
  goToSummary = () => {
    this.setState({ currentStep: 1 });
    this.setState({ currentSubStep: 4 });
    this.goToAnnotationId(4);
  };
  //currentSubStep
  goToSubStep = (step) => {
    this.setState({ currentSubStep: step });
    this.goToAnnotationId(step);
  };

  goToPrevStep = () => {
    this.setState((prevState) => {
      if (prevState.currentSubStep < 3) {
        const newStep =
          prevState.currentSubStep > 0
            ? prevState.currentSubStep - 1
            : prevState.currentSubStep;
        this.goToAnnotationId(newStep);
        return { currentSubStep: newStep };
      } else {
        //this.setState({ currentSubStep: 3 });
        const newStep =
          prevState.currentStep > 0
            ? prevState.currentStep - 1
            : prevState.currentStep;
        this.setState({ currentSubStep: newStep });
        this.goToAnnotationId(0);
        return { currentStep: newStep };
      }
    });
  };
  // goToStudio
  goToNextStep = () => {
    this.setState((prevState) => {
      if (prevState.currentSubStep < 3) {
        const newStep2 =
          prevState.currentSubStep < this.navSteps.length - 1
            ? prevState.currentSubStep + 1
            : prevState.currentSubStep;

        this.goToAnnotationId(newStep2);
        return { currentSubStep: newStep2 };
      } else {
        //this.setState({ currentSubStep: 0 });
        this.setState({ currentSubStep: 4 });
        const newStep =
          prevState.currentStep < this.steps.length - 1
            ? prevState.currentStep + 1
            : prevState.currentStep;
        this.goToAnnotationId(4);

        return { currentStep: newStep };
      }
    });
  };

  goToStudio = () => {
    this.state.config?.sketchFab.apiRef.current.hide(907);
    this.state.config?.sketchFab.apiRef.current.hide(1204);

    this.state.config?.sketchFab.apiRef.current.show(1171);
    this.state.config?.sketchFab.apiRef.current.show(1186);
    this.setState({ isStudio: 1 });
  };
  goToStudio2 = () => {
    this.state.config?.sketchFab.apiRef.current.hide(1171);
    this.state.config?.sketchFab.apiRef.current.hide(1186);

    this.state.config?.sketchFab.apiRef.current.show(907);
    this.state.config?.sketchFab.apiRef.current.show(1204);

    this.setState({ isStudio: 0 });
  };

  handleChangeModel = (model) => {
    this.setState({ config: initialConfig[model] });
  };

  handleOnSelectOption = (prop, value) => {
    if (prop === "model") {
      this.handleChangeModel(value);
    } else {
      this.setState((prevState) => ({
        config: {
          ...prevState.config,
          [prop]: value,
        },
      }));
    }
  };

  handleOnChangeOption = (prop, value) => {
    this.setState((prevState) => ({
      config: {
        ...prevState.config,
        [prop]: value,
      },
    }));
  };
  goToAnnotationId = (step) => {
    this.state.config?.sketchFab?.apiRef.current.gotoAnnotation(step, {
      preventCameraAnimation: false,
      preventCameraMove: false,
    });
    this.state.config?.sketchFab.apiRef.current.setCameraEasing("easeInQuad");
  };
  render() {
    const isFirstStep = this.state.currentSubStep === 0;
    const isLastStep = this.state.currentStep === this.steps.length - 1;
    const isStudioActive = this.state.isStudio;
    return (
      <div className="app">
        <Preloader />

        <Menu
          items={this.steps.map((step) => step.name)}
          selectedItem={this.state.currentStep}
          onSelectItem={this.goToStep}
        />
        {this.steps[this.state.currentStep]?.settings ? (
          <MenuSub
            items={this.steps[this.state.currentStep]?.settings.map(
              (step) => step
            )}
            selectedItem={this.state.currentSubStep}
            onSelectItem={this.goToSubStep}
          />
        ) : null}

        <main className="app-content">
          <Viewer
            config={this.state.config}
            models={models}
            showAllModels={isFirstStep}
            showSpecs={!isLastStep}
            onChangeModel={this.handleChangeModel}
          />
          {isLastStep ? (
            <Summary
              config={this.state.config}
              models={models}
              totalPrice={this.totalPrice}
            />
          ) : (
            <Settings
              config={this.state.config}
              models={models}
              settings={this.steps[this.state.currentStep].settings}
              onSelectOption={this.handleOnSelectOption}
              selectedSubItem={this.state.currentSubStep}
            />
          )}
        </main>
        <Footer
          totalPrice={this.totalPrice}
          disablePrev={isFirstStep}
          disableNext={isLastStep}
          onClickPrev={this.goToPrevStep}
          onClickNext={this.goToNextStep}
          onClickStudio={this.goToStudio}
          onClickStudio2={this.goToStudio2}
          activeStudioBtn={isStudioActive}
          nextStep={this.navSteps[this.state.currentSubStep + 1]?.name}
          prevStep={this.navSteps[this.state.currentSubStep - 1]?.name}
          showQuote={isLastStep}
          onClickSummary={this.goToSummary}
        />
      </div>
    );
  }
}

export default Home;
