export function formatNumber(value) {
  //return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  //console.log("Dollars: " + USDollar.format(value));
  return USDollar.format(value);
}

export function formatPrice(value, zero = "included") {
  if (isNaN(value)) return null;
  //else if (value === -0.0) return null;
  return value === 0 ? zero : `${formatNumber(value)}`;
}
