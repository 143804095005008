import React, { useRef } from "react";

import PropTypes from "prop-types";
// Styles
import "./Viewer.css";
// Components
import { Model } from "../Viewer/Model";

/*
 * TODO: Refactor Editor to leverage React hooks
 *
 * Requirements:
 * - store selectedOptions in React state using the useState hook
 * - initialize state using lazy initialization
 * - use other React hooks if needed
 *
 */
const Viewer = ({ config = null, settings = null }) => {
  const apiRef = useRef(null);
  config.sketchFab = { apiRef };

  return (
    <div className="preview">
      <div className="slideshow">
        <div className="slideshow-slide active">
          <Model apiRef={apiRef} />
        </div>
      </div>
    </div>
  );
};

Viewer.propTypes = {
  config: PropTypes.object,
};

export default Viewer;
