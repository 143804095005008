import React from "react";
import PropTypes from "prop-types";
import { formatPrice } from "../../utils";
// Styles
import "./Summary.css";
import { Fade } from "react-awesome-reveal";
// https://leanylabs.com/blog/form-validation-in-react/
/*
 * TODO
 *
 * Requirements:
 * - use React hooks if needed
 * - use performance optimization if needed
 *
 */

const Summary = ({ config = null, models = null, totalPrice = 0 }) => {
  const selectedModel = models?.find((model) => model?.key === config?.model);
  const selectedType = selectedModel?.types?.find(
    (type) => type.value === config?.car_type
  );
  const selectedColor = selectedModel?.colors?.find(
    (color) => color.value === config?.color
  );
  const selectedWheels = selectedModel?.wheels?.find(
    (wheels) => wheels.value === config?.wheels
  );

  const selectedStyling = selectedModel?.styling?.find(
    (styling) => styling.value === config?.styling
  );
  const selectedStorage = selectedModel?.storage?.find(
    (storage) => storage.value === config?.storage
  );

  return (
    <div className="summary">
      <h2>
        Your CFMoto
        <br />
        CFORCE 450L
      </h2>

      {/* <p className="summary-edd">Estimated delivery: 5-9 weeks</p> */}
      <div className="summary-content">
        <p>Summary</p>
        <Fade cascade damping={0.1} triggerOnce={true}>
          <ul>
            <li>
              <h5>Model</h5>
              <div className="summary-content-row">
                <span>{selectedModel?.name}</span>
                <span className="summary-content-row-price">
                  {formatPrice(selectedType?.price)}
                </span>
              </div>
            </li>
            <li>
              <h5>Paint</h5>
              <div className="summary-content-row">
                <span>{selectedColor?.label}</span>
                <span className="summary-content-row-price">
                  {formatPrice(selectedColor?.price)}
                </span>
              </div>
            </li>
            <li>
              <h5>Front Bumper</h5>
              <div className="summary-content-row">
                <span>{selectedStyling?.label}</span>
                <span className="summary-content-row-price">
                  {formatPrice(selectedStyling?.price)}
                </span>
              </div>
            </li>
            <li>
              <h5>Hanguard</h5>
              <div className="summary-content-row">
                <span>{selectedWheels?.label}</span>
                <span className="summary-content-row-price">
                  {formatPrice(selectedWheels?.price)}
                </span>
              </div>
            </li>
            <li>
              <h5>Cargo Box</h5>
              <div className="summary-content-row">
                <span>{selectedStorage?.label}</span>
                <span className="summary-content-row-price">
                  {formatPrice(selectedStorage?.price)}
                </span>
              </div>
            </li>
          </ul>
        </Fade>
        {/* <p>
          <span>
            Total Cost <span className="text-weight-400">(MSRP)</span>
          </span>
          <span className="text-primary">{formatPrice(totalPrice)}</span>
        </p> */}
      </div>
    </div>
  );
};

Summary.propTypes = {
  config: PropTypes.object,
  models: PropTypes.array,
  totalPrice: PropTypes.number,
};

export default Summary;
