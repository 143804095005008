export const colors = [
  {
    label: "Lava Orange",
    src: `${process.env.PUBLIC_URL}/colors/color-1@2x.webp`,
    value: "LO",
    price: 400,
    input: "radio",
    input_name: "paint_color",
    selector: 11,
  },
  {
    label: "Desert Tan",
    src: `${process.env.PUBLIC_URL}/colors/color-2@2x.webp`,
    value: "DT",
    price: 0,
    input: "radio",
    input_name: "paint_color",
    selector: 12,
  },
  {
    label: "Tactical Green",
    src: `${process.env.PUBLIC_URL}/colors/color-3@2x.webp`,
    value: "TG",
    price: 0,
    input: "radio",
    input_name: "paint_color",
    selector: 13,
  },
  {
    label: "Magma Red",
    src: `${process.env.PUBLIC_URL}/colors/color-4@2x.webp`,
    value: "MR",
    price: 0,
    input: "radio",
    input_name: "paint_color",
    selector: 14,
  },
  {
    label: "Velocity Grey",
    src: `${process.env.PUBLIC_URL}/colors/color-5@2x.webp`,
    value: "VG",
    price: 0,
    input: "radio",
    input_name: "paint_color",
    selector: 15,
  },
  {
    label: "Jet Black",
    src: `${process.env.PUBLIC_URL}/colors/color-6@2x.webp`,
    value: "JB",
    price: 0,
    input: "radio",
    input_name: "paint_color",
    selector: 16,
  },
  {
    label: "Hunter Green",
    src: `${process.env.PUBLIC_URL}/colors/color-7@2x.webp`,
    value: "HG",
    price: 0,
    input: "radio",
    input_name: "paint_color",
    selector: 17,
  },
  {
    label: "Royal Blue",
    src: `${process.env.PUBLIC_URL}/colors/color-8@2x.webp`,
    value: "RB",
    price: 0,
    input: "radio",
    input_name: "paint_color",
    selector: 18,
  },
];

export const styling = [
  {
    label: "Default Bumper",
    src: `${process.env.PUBLIC_URL}/styling/styling-1@2x.webp`,
    value: "nstyle",
    price: 0,
    input: "radio",
    input_name: "styling_options",
    selector: 1090,
  },
  {
    label: "CFORCE Front Bumper 400/500 (New)",
    src: `${process.env.PUBLIC_URL}/styling/styling-2@2x.webp`,
    value: "utrack",
    price: 279.99,
    input: "radio",
    input_name: "styling_options",
    selector: 1017,
  },
];
export const wheels = [
  {
    label: "Default Handguard",
    src: `${process.env.PUBLIC_URL}/wheels/wheels-1@2x.webp`,
    value: "17A",
    price: 0,
    input: "radio",
    input_name: "wheel_type",
    selector: 1063,
  },
  {
    label: "CFORCE Handguard",
    src: `${process.env.PUBLIC_URL}/wheels/wheels-2@2x.webp`,
    value: "17B",
    price: 57.99,
    input: "radio",
    input_name: "wheel_type",
    selector: 1049,
  },
];
export const storage = [
  {
    label: "No Cargo Box",
    src: `${process.env.PUBLIC_URL}/storage/storage-1@2x.webp`,
    value: "nstorage",
    //price: 100,
    input: "radio",
    input_name: "storage_type",
    selector: 11170000,
  },
  {
    label: "CFORCE Front Cargo Box 400/500 (New)",
    src: `${process.env.PUBLIC_URL}/storage/storage-2@2x.webp`,
    value: "ftilt4",
    price: 459.99,
    input: "radio",
    input_name: "storage_1",
    selector: 1117,
  },
];

export const models = [
  {
    key: "s",
    name: "CFORCE 450L",
    colors: colors,
    styling: styling,
    wheels: wheels,
    storage: storage,
    types: [
      {
        label: "2023 Cew Cab PRO-4X 4x4",
        value: "long_range_plus",
        specs: {
          range: 402,
          top_speed: 155,
          acceleration_time: 3.7,
        },
        // eslint-disable-next-line
        price: 10249,
      },
    ],
  },
];

export const initialConfig = {
  s: {
    sketchFab: null,
    car_type: "long_range_plus",
    model: "s",
    color: "LO",
    styling: "nstyle",
    wheels: "17A",
    storage: "nstorage",
  },
};
