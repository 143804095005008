import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import "./Model.css";
// Our wonderful CFMoto model
const MODEL_UID = "08e74f9bf9c648f8814ac1dd1a22fa1d";
const rootStyle = document.querySelector(":root");

const useSketchfabViewer = () => {
  // const viewerIframeRef = useRef(null);
  const [api, setApi] = useState();
  // const [activeButton, setActiveButton] = useState(null);
  const [isModelLoaded, setIsModelLoaded] = useState(false);
  // const [plasticLargePartsMaterial, setPlasticLargePartsMaterial] = useState();

  // var iframe = document.getElementById("modelNissan");
  // if (iframe) {
  //   api.addEventListener("viewerready", function () {
  //     api.getSceneGraph(function (err, result) {
  //       if (err) {
  //         console.log("Error getting nodes");
  //         return;
  //       }
  //       // Exterior_SkySphere
  //       api.hide(1077);
  //       api.hide(1078);

  //       // Environment_CfmotoShadow
  //       api.hide(1283);
  //       api.hide(1284);
  //       // SM_Peredniy_Bumper_1
  //       // api.hide(1203);
  //       // api.hide(1204);

  //       // SM_Peredniy_Bumper_2
  //       api.hide(1152);
  //       api.hide(1153);

  //       // SM_Front_Cargo_Box
  //       api.hide(1223);

  //       // SM_Hanguard_1
  //       api.hide(1174);

  //       // Exterior_SkySphere [1077]

  //       // Studio_CfmotoShadow [1270]
  //       // Environment_CfmotoShadow [1283]

  //       // SM_Hanguard_1 [1184]
  //       // SM_Hanguard_2 [1174]

  //       // SM_Front_Cargo_Box [1222]

  //       // SM_Peredniy_Bumper_1 [1203]
  //       // SM_Peredniy_Bumper_2 [1152]
  //     });
  //     //console.log("View Ready");
  //   });
  // }

  const ViewerIframe = (
    <iframe
      // ref={viewerIframeRef}
      className="api-iframe-model"
      id="modelNissan"
      title="CFORCE 450L"
      style={{ border: 0 }}
      allow="autoplay; fullscreen"
    />
  );

  useEffect(() => {
    const frame = document.querySelector("#modelNissan");
    let enablePreload = true;
    if (isMobile) {
      enablePreload = false;
    }
    if (frame) {
      // eslint-disable-next-line no-undef
      const client = new Sketchfab(frame);

      client.init(MODEL_UID, {
        success: onSuccess,
        error: () => {
          console.log("Viewer error");
        },
        camera: 0,
        preload: enablePreload, //enablePreload
        continuousRender: 1,
        dnt: 1,
        autostart: 1,
        animation_autoplay: 0,
        annotation: 0,
        annotation_tooltip_visible: 0,
        annotations_visible: 0,
        ui_animations: 0,
        ui_controls: 0,
        ui_infos: 0,
        ui_stop: 0,
        ui_watermark_link: 0,
        ui_watermark: 0,
        ui_help: 0,
        ui_ar: 0,
        ui_hint: 0,
        ui_ar_help: 0,
        ui_ar_qrcode: 0,
        ui_inspector: 0,
        ui_settings: 0,
        ui_annotations: 0,
        ui_fullscreen: 0,
        transparent: 0,
        ui_loading: 0,
        ui_color: "4499b1",
        orbit_constraint_pan: 1,
        orbit_constraint_zoom_in: 2,
        orbit_constraint_zoom_out: 5,
        orbit_constraint_pitch_down: 0.07,
      });
    }
  }, []);

  function onSuccess(api) {
    api.start(
      api.addEventListener("modelLoadProgress", (factor) => {
        const progress = Math.round(factor.progress * 100);
        rootStyle.style.setProperty("--bg-size", `${progress}%`);
        if (progress === 100) {
          rootStyle.classList.add("loaded");
          rootStyle.style.setProperty("--bg-loader", "none");
        } else {
          rootStyle.classList.remove("loaded");
          rootStyle.style.setProperty("--bg-loader", "flex");
        }
      }),

      api.addEventListener("viewerready", function () {
        setTimeout(() => setIsModelLoaded(true), 700);
        api.setCameraEasing("easeLinear");

        //loaderStyle.style.setProperty("display", "none");
        api.getSceneGraph(function (err, result) {
          if (err) {
            console.log("Error getting nodes");
            return;
          }
          //console.log("result result", result);
          // Exterior_SkySphere = 907
          // Environment_CfmotoShadow = 1204

          // SM_Studio = 1171
          // Studio_CfmotoShadow = 1186

          // SM_Hanguard_1 = 1063
          // SM_Hanguard_2 = 1049

          // SM_Bumper = 812
          // SM_Peredniy_Bumper_1 = 1090
          // SM_Peredniy_Bumper_2 = 1017

          // SM_Front_Cargo_Box = 1117
          api.hide(907);
          api.hide(1204);
          api.hide(1017);
          api.hide(1049);
          api.hide(1117);
        });
      })
    );
    api.getMaterialList(function (error, materials) {
      if (!error) {
        const plasticMaterial = materials.find(
          (material) => material.name === "Body_Color"
        );
        if (plasticMaterial) {
          plasticMaterial.channels.AlbedoPBR.factor = 1;
          plasticMaterial.channels.AlbedoPBR.enable = true;
          plasticMaterial.channels.DiffuseColor.enable = false;
          plasticMaterial.channels.AlbedoPBR.color = [
            1, 0.04970656598412723, 0.004776953480693729,
          ];
        }
      }
    });
    if (isModelLoaded) {
      setApi(api);
    }
    setApi(api);
  }

  return [ViewerIframe, api];
};

export const Model = ({ apiRef }) => {
  const [ViewerIframe, api] = useSketchfabViewer();
  apiRef.current = api;
  return ViewerIframe;
};
