import React from "react";

import "./Preloader.css";

class Preloader extends React.Component {
  render() {
    const loaderLogo = `${process.env.PUBLIC_URL}/logo.svg`;

    return (
      <div className="progress-div">
        <img className="loaderImage" src={loaderLogo} alt="CFORCE 450L" />
        <div className="progress-bar">
          <div className="progress-value" id="progress-val"></div>
        </div>
      </div>
    );
  }
}

export default Preloader;
