import React from "react";
import PropTypes from "prop-types";
// Styles
import "./MenuSub.css";
// Icons
import { Fade } from "react-awesome-reveal";

/*
 * TODO: Refactor Menu as a functional component
 *
 * Requirements:
 * - Create a custom hook to implement dark mode named useDarkMode
 * - Switch from setState to the useDarkMode hook
 * - Use function closures instead of this for callbacks and event handlers
 * - Menu logic and behavior should remain the same
 *
 */
class MenuSub extends React.Component {
  state = {
    darkMode: true,
  };

  handleOnChangeMode = () => {
    this.setState((prevState) => ({
      ...prevState,
      darkMode: !prevState.darkMode,
    }));
  };

  render() {
    return (
      <div className="menu-container-sub">
        <Fade cascade damping={0.1}>
          <ul className="menu-nav">
            {this.props.items.map((item, i) =>
              item && item?.parent === "root" ? (
                <li
                  key={item.name}
                  onClick={() => this.props.onSelectItem(i)}
                  className={this.props.selectedItem === i ? "selected" : null}
                >
                  <h2>{item.name}</h2>
                </li>
              ) : null
            )}
          </ul>
        </Fade>
      </div>
    );
  }
}

MenuSub.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  selectedItem: PropTypes.number,
  onSelectItem: PropTypes.func,
};

export default MenuSub;
